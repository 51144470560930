@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.LinkBlock {
  padding-left: var(--page-gutter);
  padding-right: var(--page-gutter);
}

.lineInner {
  width: 100%;
  height: 1px;
  background-color: var(--fg);
  transform-origin: left;
  transform: scaleX(0);
}

.content {
  padding-top: px(10);

  @include bp(tablet) {
    padding-top: px(20);
  }
}

.title {
  @include font-body-6;
  margin-bottom: px(23);

  @include bp(tablet) {
    margin-bottom: px(41);
  }
}

.contentLinkAndCount {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.link {
  display: block;

  @include hover {
    .linkTitle__svgContainer {
      transform: scale(0.8) translate(10%, -10%);
    }
  }
}

.linkInner {
  display: block;
  @include flex-center;
  padding-right: px(5);
}

.linkTitle {
  @include font-h1;
}

.linkTitle__svgContainer {
  display: block;
  width: px(7);
  margin-left: px(6);
  transform-origin: top right;
  transition: transform 0.3s;

  @include bp(tablet) {
    width: px(27);
    margin-left: px(21);
  }
}

.arrowRight {
  display: block;
}

.productCount {
  @include font-h1;
}
