@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.TwoImagesAndText {
  padding-left: var(--page-gutter);
  padding-right: var(--page-gutter);
  display: flex;
  flex-direction: column;

  @include bp(tablet) {
    flex-direction: row;
    display: grid;
    grid-template-columns: 0.18fr 0.82fr;
    gap: px(21);
  }
}

.title {
  @include font-body-mono;

  @include bp(tablet, true) {
    padding-top: px(12);
    border-top: 1px solid var(--fg);
  }
}

.images {
  display: grid;
  gap: px(7);
  margin-top: px(24);
  grid-template-columns: 0.4fr 0.6fr;

  @include bp(tablet) {
    gap: px(21);
    margin-top: 0;
  }
}

.mediaContainer {
  height: min-content;
}

.smallImage,
.largeImage {
  display: block;
  width: 100%;
  height: unset;
  object-fit: cover;
}

.smallImage {
  aspect-ratio: 391/469;
}

.largeImage {
  aspect-ratio: 666/772;
}
