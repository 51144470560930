@import "@/styles/shared";
$large-height: 532;
$large-width: 414;
$small-height: 328;
$small-width: 255;

$large-height-mobile: 189;
$large-width-mobile: 135;
$small-height-mobile: 111;
$small-width-mobile: 86;

/* stylelint-disable-next-line block-no-empty */
.ProductsInfiniteCarousel {
  padding-left: var(--page-gutter);
  padding-right: var(--page-gutter);
  position: relative;
  overflow: hidden;

  --large-item-height: #{px($large-height-mobile)};
  --large-item-width: #{px($large-width-mobile)};
  --large-aspect-ratio: #{$large-width-mobile}/#{$large-height-mobile};
  --small-item-height: #{px($large-height-mobile)};
  --small-item-width: #{px($small-width-mobile)};
  --small-aspect-ratio: #{$small-width-mobile}/#{$small-height-mobile};
  --gap: #{px(7)};

  @include bp(tablet) {
    --large-item-height: #{px($large-height)};
    --large-item-width: #{px($large-width)};
    --large-aspect-ratio: #{$large-width}/#{$large-height};
    --small-item-height: #{px($large-height)};
    --small-item-width: #{px($small-width)};
    --small-aspect-ratio: #{$small-width}/#{$small-height};
    --gap: #{px(17)};
  }
}

.titlesContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin-bottom: px(29);

  @include bp(tablet) {
    margin-bottom: px(49);
  }
}

.productTitles {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, #{px(-4)});
  display: grid;
  grid-template-areas: 'a';
  text-align: center;

  @include bp(tablet) {
    transform: translateX(-50%);
  }
}

.productTitleContainer {
  grid-area: a;
  @include gpu;
}

.productTitleContainer__index {
  @include font-body-mono;
  @include gpu;
  opacity: 0.5;
  position: relative;
  overflow: hidden;
  padding-top: px(5);

  span {
    display: block;
    transform: translateY(105%);
  }
}

.productTitleContainer__title {
  @include font-body-6;
  @include gpu;
  position: relative;
  overflow: hidden;

  span {
    display: block;
    transform: translateY(105%);
  }
}

.titleLeft,
.titleRight {
  @include font-h3;

  // Todo: Fix font for this
  @include bp(tablet, true) {
    font-size: px(22) !important;
    line-height: px(22) !important;
  }
}

.productTrack {
  position: relative;
}

.boxPositionSmall {
  width: var(--small-item-width);
  aspect-ratio: var(--small-aspect-ratio);
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0.1;
}

.boxPositionLarge {
  opacity: 0.1;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  width: var(--large-item-width);
  aspect-ratio: var(--large-aspect-ratio);
}

.productTrackItemList {
  min-height: var(--large-item-height);
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  transform: translateX(calc(var(--small-item-width) * -1));

  .isEvenNumber & {
    transform: translateX(calc(var(--small-item-width) * -0.5 + calc(var(--small-item-width) * -1)));
  }
}

.trackPosition {
  min-width: var(--small-item-width);
  aspect-ratio: var(--small-aspect-ratio);
  opacity: 0.1;
}

.productContainer {
  position: absolute;
  width: var(--small-item-width);
  padding: 0 calc(var(--gap) * 0.5);
  left: 0;
}

.productContainer__inner {
  transform-origin: top;
  width: 100%;
}

.imageReveal {
  position: relative;
  aspect-ratio: var(--small-aspect-ratio);
}

.productContainer__image {
  @include position-100(absolute);
  object-fit: cover;
}

.link {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 1px;
}
