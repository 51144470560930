@import "@/styles/shared";
$original-placement-size: px(437);
$original-placement-size-mobile: px(170);

.ResizingSlider {
  padding-left: var(--page-gutter);
  padding-right: var(--page-gutter);
  position: relative;
  overflow: hidden;

  --original-placement-size: #{$original-placement-size-mobile};

  @include bp(tablet) {
    --original-placement-size: #{$original-placement-size};
  }
}

.topContent {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
  z-index: 4;
}

.topContent__title {
  @include font-h2;
  white-space: pre-line;
}

.imagesText__length {
  @include font-body-mono;
  opacity: 0.5;
}

.imagesText__title {
  @include font-body-5;
}

.carouselContent {
  margin-top: px(20);

  @include bp(tablet) {
    margin-top: px(-40);
  }
}

.carouselContentInner {
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.productPlacement1,
.productPlacement4 {
  position: absolute;
}

.productPlacement1 {
  width: px(71);
  height: px(93);
  bottom: 0;
  right: 130%;

  @include bp(tablet) {
    width: px(209);
    height: px(300);
  }
}

.productPlacement2 {
  width: px(104);
  height: px(136);

  @include bp(tablet) {
    width: px(267);
    height: px(380);
  }
}

.productPlacement3 {
  width: var(--original-placement-size);
  height: px(223);

  @include bp(tablet) {
    width: var(--original-placement-size);
    height: px(590);
  }
}

.productPlacement4 {
  width: px(214);
  height: px(281);
  bottom: 0;
  left: 130%;

  @include bp(tablet) {
    width: px(541);
    height: px(711);
  }
}

.productContainer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: var(--original-placement-size);
}

.productImageContainer {
  width: 100%;
  aspect-ratio: 267/351;
  position: relative;
  transform-origin: bottom left;

  .isPrev & {
    cursor: w-resize;
  }

  .isNext & {
    cursor: e-resize;
  }
}

.productImageContainerMask {
  @include position-100(absolute);
}

.productImage {
  @include position-100(absolute);
  object-fit: cover;
}

.productTextContent {
  margin-top: px(19);
}

.productTextContent__title {
  @include font-body-5;
}

.productTextContent__link {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
