@mixin font-h1 {
  font-family: $body-fonts;
  font-style: normal;
  font-weight: 500;
  font-size: px(22);
  line-height: px(22);

  @include bp(tablet) {
    font-size: px(85);
    line-height: px(85);
  }
}

@mixin font-h2 {
  font-family: $body-fonts;
  font-style: normal;
  font-weight: 500;
  font-size: px(22);
  line-height: px(22);

  @include bp(tablet) {
    font-size: px(59);
    line-height: px(57);
  }
}

@mixin font-h3 {
  font-family: $body-fonts;
  font-style: normal;
  font-weight: 500;
  font-size: px(26);
  line-height: px(26);

  @include bp(tablet) {
    font-size: px(48);
    line-height: px(60);
  }
}

@mixin font-h4 {
  font-family: $body-fonts;
  font-style: normal;
  font-weight: 500;
  font-size: px(18);
  line-height: px(18);

  @include bp(tablet) {
    font-size: px(48);
    line-height: px(60);
  }
}

@mixin font-h5 {
  font-family: $body-fonts;
  font-style: normal;
  font-weight: 500;
  font-size: px(22);
  line-height: px(22);

  @include bp(tablet) {
    font-size: px(32);
    line-height: px(32);
  }
}

@mixin font-body-1 {
  font-family: $body-fonts;
  font-style: normal;
  font-weight: 450;
  font-size: px(16);
  line-height: px(18);

  @include bp(tablet) {
    font-size: px(50);
    line-height: px(57);
  }
}

@mixin font-body-2 {
  font-family: $body-fonts;
  font-style: normal;
  font-weight: 500;
  font-size: px(36);
  line-height: px(39);
}

@mixin font-body-3 {
  font-family: $body-fonts;
  font-style: normal;
  font-weight: 500;
  font-size: px(18);
  line-height: px(25);
}

@mixin font-body-4 {
  font-family: $body-fonts;
  font-style: normal;
  font-weight: 500;
  font-size: px(12);
  line-height: px(12);
}

@mixin font-body-5 {
  font-family: $body-fonts;
  font-style: normal;
  font-weight: 500;
  font-size: px(12);
  line-height: px(12);

  @include bp(tablet) {
    font-size: px(14);
    line-height: px(16);
  }
}

@mixin font-body-6 {
  font-family: $body-fonts;
  font-style: normal;
  font-weight: 500;
  font-size: px(12);
  line-height: px(12);

  @include bp(tablet) {
    font-size: px(16);
    line-height: px(16);
  }
}

@mixin font-body-7 {
  font-family: $body-fonts;
  font-style: normal;
  font-weight: 500;
  font-size: px(22);
  line-height: px(24);
}

@mixin font-navigation-mobile {
  font-family: $body-fonts;
  font-style: normal;
  font-weight: 500;
  font-size: px(35);
  line-height: px(35);
}

@mixin font-body-mono {
  font-family: $secondary-fonts;
  font-style: normal;
  font-weight: 500;
  text-transform: uppercase;
  font-size: px(12);
  line-height: px(12);

  @include bp(tablet) {
    font-size: px(14);
    line-height: px(16);
  }
}

@mixin font-body-mono-2 {
  font-family: $secondary-fonts;
  font-style: normal;
  font-weight: 500;
  font-size: px(12);
  line-height: px(12);
}
