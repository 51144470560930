@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.ProductGridHeader {
  padding-left: var(--page-gutter);
  padding-right: var(--page-gutter);
}

.titleCountContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: px(30);

  @include bp(tablet) {
    margin-bottom: px(40);
  }
}

.title {
  @include font-h1;
  opacity: 0;
}

.count {
  @include font-h1;
  opacity: 0;
}

.sliderAndTextViewContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: px(40);
}
