@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.ColumnView {
  width: 100%;
  padding-left: var(--page-gutter);
  padding-right: var(--page-gutter);
}

.grid {
  display: flex;
  flex-direction: column;
  gap: px(31);
  align-items: center;
  width: 100%;

  @include bp(tablet) {
    gap: px(9);
    margin-top: px(-230);
  }
}

.item {
  @include bp(tablet, true) {
    width: 100%;
  }
}

.link {
  display: block;
}

.imageContainer {
  width: 100%;
  aspect-ratio: 258 / 344;
  position: relative;

  @include bp(tablet) {
    width: px(524);
  }
}

.image {
  @include position-100(absolute);
  object-fit: cover;
}

.index {
  @include font-body-mono-2;
  opacity: 0.5;
  margin-bottom: px(5);

  @include bp(tablet) {
    display: none;
  }
}

.titlesContainer {
  margin-top: px(15);
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include bp(tablet) {
    display: none;
  }
}

.titlesContainer__model {
  @include font-body-6;
}

.titlesContainer__title {
  @include font-body-6;
}
