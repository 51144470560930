@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.ProductHeroImageRotator {
  --image-dimensions: #{px(500)};
  position: relative;
}

.inner {
  @include gpu;
  width: 100%;
  max-width: 100vw;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  min-height: var(--image-dimensions);
}

.track {
  transform-origin: top left;
}

.track__inner {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: min-content;
}

.boxSizingReference,
.imageContainer {
  width: var(--image-dimensions);
  height: var(--image-dimensions);
}

.boxSizingReference {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  pointer-events: none;
}

.imageContainer {
  position: absolute;
  left: 0;
  top: 0;
}

.image {
  @include position-100(absolute);
  user-select: none;
  object-fit: cover;

  &.large {
    z-index: 2;
  }
}

.closeButton {
  @include gpu;
  @include font-h5;
  color: white;
  mix-blend-mode: difference;
  position: absolute;
  right: var(--page-gutter);
  top: var(--page-gutter);
  z-index: 999999999999; // fix for safari - it would randomly disappear
  transition: opacity 0.3s;

  .isExpanded & {
    opacity: 0;
    pointer-events: none;
  }
}
