@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.ProductGridImageTextToggle {
  @include font-body-mono;
  @include flex-center;
  gap: px(3);
}

.imageButton,
.textButton {
  @include reset-button;
  @include font-body-mono;
  position: relative;
  opacity: 0.5;
  transition: 0.3s;

  &::after {
    content: '';
    display: block;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: var(--fg);
    height: 1px;
    margin-top: px(-1);
    opacity: 0;
    transition: 0.3s;
  }

  &.isActive {
    opacity: 1;

    &::after {
      opacity: 1;
    }
  }
}

// .slash {
// }
