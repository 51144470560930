@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.MultiParagraphWithLinks {
  padding-left: var(--page-gutter);
  padding-right: var(--page-gutter);
}

.topParagraph {
  p {
    @include font-body-1;
    font-weight: 400;
  }

  sup {
    @include font-body-5;
    display: block;
    margin-bottom: px(16);

    @include bp(tablet) {
      margin-bottom: 0;
      margin-right: px(80);
      margin-top: -4em;
      display: inline-block;
    }
  }
}

.bottomContent {
  margin-top: px(39);
  gap: px(40);
  display: flex;
  flex-direction: column;

  @include bp(tablet) {
    margin-top: px(67);
    gap: px(170);
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
  }
}

.bottomContent__descriptionContainer {
  width: 100%;

  @include bp(tablet) {
    width: px(385);
  }

  p {
    @include font-body-5;
    line-height: 1.5;

    &:not(:first-child) {
      margin-top: px(20);
    }
  }
}

.bottomContent__linksContainer {
  width: px(273);

  @include bp(tablet) {
    width: px(385);
  }
}

.bottomContent__linksContainer__title {
  @include font-body-5;
  margin-bottom: px(16);
}

.bottomContent__linksContainer__list {
  display: flex;
  flex-direction: column;
}

.bottomContent__linksContainer__listItem {
  width: 100%;
}

.bottomContent__linksContainer__listItemLine {
  border-top: 1px solid var(--fg);
  width: 100%;
  display: block;
  transform: scaleX(0);
  transform-origin: left center;
}

.bottomContent__linksContainer__link {
  padding: px(11) 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include hover {
    .bottomContent__linksContainer__linkBg {
      transform: none;
    }

    .bottomContent__linksContainer__linkLabel {
      transform: translateX(#{px(20)});
    }

    .bottomContent__linksContainer__linkArrow {
      transform: translateX(#{px(-20)});
    }

    .bottomContent__linksContainer__linkLabel,
    .bottomContent__linksContainer__linkArrow {
      color: var(--fg-invert);
    }
  }
}

.bottomContent__linksContainer__linkBg {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: var(--fg);
  z-index: 0;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.6s;
}

.bottomContent__linksContainer__linkLabel {
  @include font-body-5;
  z-index: 2;
  position: relative;
  transition:
    color 0.4s,
    transform 0.4s;
}

.bottomContent__linksContainer__linkArrow {
  z-index: 2;
  position: relative;
  transition:
    color 0.4s,
    transform 0.4s;

  svg {
    width: px(10);

    @include bp(tablet) {
      width: px(11);
    }
  }
}
