@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.SmallGridView {
  padding-left: var(--page-gutter);
  padding-right: var(--page-gutter);
}

.grid {
  display: grid;
  flex-direction: row;
  grid-template-columns: repeat(3, 1fr);
  column-gap: px(34);
  row-gap: px(31);

  @include bp(tablet) {
    grid-template-columns: repeat(4, 1fr);
    column-gap: px(230);
    row-gap: px(130);
  }
}

.link {
  display: block;
}

.index {
  @include font-body-mono-2;
  opacity: 0.5;
  margin-bottom: px(5);
  display: none;

  @include bp(tablet) {
    display: block;
  }
}

.imageContainer {
  width: 100%;
  aspect-ratio: 258 / 344;
  position: relative;
}

.image {
  @include position-100(absolute);
  object-fit: cover;
}

.titlesContainer {
  margin-top: px(10);
  display: none;
  align-items: center;
  justify-content: space-between;

  @include bp(tablet) {
    display: flex;
  }
}

.titlesContainer__model {
  @include font-body-4;
}

.titlesContainer__title {
  @include font-body-4;
}
