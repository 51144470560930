@use 'sass:map';

// ================================================
// Breakpoints
// ================================================

$layout: (
  mobile: 768,
  tablet: 967,
  laptop: 1200,
  desktop: 1512,
  xl: 1800,
);

$layout-mobile: map.get($layout, mobile) + px;
$layout-tablet: map.get($layout, tablet) + px;
$layout-laptop: map.get($layout, laptop) + px;
$layout-desktop: map.get($layout, desktop) + px;
$layout-xl: map.get($layout, xl) + px;

// ================================================
// Colors
// ================================================

// General
$white: #ffffff;
$black: #000000;
$dark-1: #0e0e0e;
$dark-2: #161616;
$dark-3: #1d1c1b;
$light-1: #eceae5;
$light-2: #dddad1;

// ================================================
// Grid
// ================================================
$gutter: px(21);
$page-gutter: px(21);
$page-gutter-mobile: px(15);

// ================================================
// General
// ================================================
$section-spacing-desktop: #{px(260)};
$section-spacing-mobile: #{px(120)};
$theme-transition-duration: 0.4s;

// ================================================
// Navigation
// ================================================
$navigation-drawer-height: px(480);
$navigation-drawer-animation-distance: $navigation-drawer-height + $page-gutter + $page-gutter;

// ================================================
// Fonts
// ================================================
$body-font-name: 'Neue Haas Grotesk Display Pro';
$secondary-font-name: 'ABC Monument Grotesk Mono';
$body-fonts: $body-font-name, sans-serif;
$secondary-fonts: $secondary-font-name, serif;

// ================================================
// z-index data (see z-index mixin)
// ================================================
$elements: next-product, main, product-grid-text-image, product-grid-title-count, product-grid-back-to-top-button,
  navigation, navigation-drawer, mobile-navigation, preloader, wipe, zoom-view, preview-button, cursor;
