@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.Footer {
  padding-left: var(--page-gutter);
  padding-right: var(--page-gutter);
  padding-bottom: var(--page-gutter);
}

.grid {
  display: grid;
  grid-template-columns: 0.2fr 0.15fr 0.25fr 0.15fr 0.1fr 0.15fr;
  align-items: flex-end;

  @include bp(tablet, true) {
    grid-template-columns: 0.5fr 0.5fr;
    row-gap: px(15);
  }
}

.title {
  text-transform: uppercase;
}

.aboutTitle,
.title,
.copyright,
.titleCopyright,
.contactTitle,
.contactTitle__contactText,
.locationTitle {
  @include font-body-5;
  white-space: pre-line;
  display: block;
}

.aboutTitle {
  @include bp(tablet, true) {
    order: 3;
  }
}

.titleCopyright {
  @include bp(tablet, true) {
    text-align: right;
    order: 4;
  }
}

.contactTitle {
  @include bp(tablet, true) {
    order: 1;
  }
}

.locationTitle,
.getInTouch {
  @include bp(tablet, true) {
    display: none !important;
  }
}

.socialLinks {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @include bp(tablet, true) {
    order: 2;
  }
}

.linkWithArrow__link {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: px(5);
}

.linkWithArrow__linkLabel {
  @include font-body-7;

  // Todo: Fix font for this
  @include bp(tablet, true) {
    font-size: px(16);
    line-height: px(16);
  }
}

.linkWithArrow__arrow {
  width: px(8);

  @include bp(tablet, true) {
    width: px(5);
  }
}

.getInTouch {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
