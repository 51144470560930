@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.ProductGrid {
  // min-height: calc(100svh - #{px(200)});
  display: flex;
  flex-direction: column;
  position: relative;
  padding-top: px(0);

  @include bp(tablet) {
    padding-top: px(85);
  }
}

.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: var(--product-grid-header-padding);
  z-index: 3;
}

.gridsInner {
  position: relative;
  width: 100%;
}

.grid {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-height: 1px;
  height: 100%;
  padding-top: px(160);
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s;
  overflow: hidden;

  @include bp(tablet) {
    padding-top: px(200);
  }

  [data-hovering-slider='true'] & {
    opacity: 0 !important;
  }

  &.isActive {
    max-height: none;
    position: relative;
    overflow: visible;
    opacity: 1;
    pointer-events: all;
  }

  [data-is-moving-boxes='true'] & {
    opacity: 0 !important;
  }
}

.movingBoxes {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s;

  [data-is-moving-boxes='true'] & {
    opacity: 1 !important;
  }

  [data-hovering-slider='true'] & {
    opacity: 1;
  }
}
