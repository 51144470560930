@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.ProductHeroMobileSlider {
  padding-left: var(--page-gutter);
  padding-right: var(--page-gutter);
}

.inner {
  width: 100%;
  display: block;
  position: relative;
  overflow: hidden;
}

.imageContainer {
  position: relative;
  aspect-ratio: 1/1;
}

.image {
  @include position-100(absolute);
  display: block;
  height: unset;
  object-fit: cover;
}

.closeButton {
  @include font-h5;
  color: white;
  mix-blend-mode: difference;
  position: absolute;
  right: var(--page-gutter);
  top: var(--page-gutter);
  z-index: 10;
}
