@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.TextView {
}

@mixin text-view-grid {
  display: grid;
  grid-template-columns: 0.45fr 0.35fr 0.2fr;
  align-items: center;

  @include bp(tablet) {
    grid-template-columns: 0.2fr 0.2fr 0.6fr;
  }
}

.headerColumns {
  @include text-view-grid;
  margin-bottom: px(10);
  padding-left: var(--page-gutter);
  padding-right: var(--page-gutter);

  @include bp(tablet) {
    margin-bottom: px(30);
  }
}

.headerColumns__colTitle {
  @include font-body-mono-2;

  &:last-child {
    text-align: right;
  }
}

.item {
  &:nth-child(odd) {
    .link {
      background-color: var(--index-text-view);
    }
  }
}

.link {
  padding-left: var(--page-gutter);
  padding-right: var(--page-gutter);
  @include text-view-grid;
  transition: background-color 0.4s;

  @include hover {
    transition: background-color 0s;
    background-color: var(--bg-invert) !important;

    .itemColumn {
      transition: color 0s;
      color: var(--fg-invert) !important;
    }
  }
}

.itemColumn {
  @include font-body-4;
  height: px(30);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transition: color 0.4s;

  &:last-child {
    @include font-body-mono-2;
    justify-content: flex-end;
    opacity: 0.5;
  }
}

.index {
  @include font-body-mono-2;
  opacity: 0.5;
}
