@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.SectionContainer {
  color: var(--fg);

  &[data-component] {
    margin: calc(var(--section-spacing) * 0.5) 0;
  }

  &:first-child {
    margin-top: 0;
    padding-top: var(--section-spacing);
  }

  &:last-child {
    margin-bottom: 0;
    padding-bottom: calc(var(--section-spacing) * 0.5);
  }

  &.hiddenOnMobile {
    @include bp(tablet, true) {
      display: none !important;
    }
  }
}

.isInfiniteDuplicate {
  height: 100svh;
  overflow: hidden;
  position: relative;
  margin-top: 0;
  padding-top: var(--section-spacing);
}

// Home logo/lith thing
$logo-padding: px(70);

.SectionContainer[data-component='lithLogo'] + .SectionContainer[data-component='logoWithProducts'] {
  margin-top: $logo-padding * -1 !important;
}

.SectionContainer[data-component='lithLogo'][data-next-section-type='logoWithProducts'] {
  margin-bottom: $logo-padding;
}

.SectionContainer[data-component='logoWithProducts'] {
  padding-top: px(50);

  @include bp(tablet) {
    padding-top: px(100);
  }
}

// Product Grid
.SectionContainer[data-component='productGrid']:first-child {
  padding-top: 0;
}

.SectionContainer[data-component='productHero']:first-child {
  padding-top: px(42);

  @include bp(tablet) {
    padding-top: px(65);
  }
}

// Four oh Four
.SectionContainer[data-component='fourOhFour'] {
  padding: 0;
  margin: 0;
}
