@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.ProductHeroDetailView {
  @include reset-button;
  display: inline-flex;

  @include hover {
    .arrowRight {
      transform: translate(50%, -50%);
    }

    .detailView {
      opacity: 0.5;
    }
  }
}

.inner {
  width: px(160);
  height: px(200);
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: relative;
  border: 1px solid var(--fg);
}

.arrowRight {
  width: px(8);
  position: absolute;
  top: px(12);
  right: px(12);
  color: var(--fg);
  transition: transform 0.4s;
}

.detailView {
  @include font-body-mono;
  text-align: center;
  padding-bottom: px(12);
  transition: opacity 0.4s;
}
