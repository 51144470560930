@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.ProductHeroDetails {
}

.aboutTitle {
  @include font-body-6;
  margin-bottom: px(17);

  @include bp(tablet) {
    margin-bottom: px(50);
  }
}

.description {
  @include font-body-1; // Todo: Fix font for this
  margin-bottom: px(40);
  opacity: 0;

  @include bp(tablet) {
    @include font-body-2;
    margin-bottom: px(90);
  }
}

.specsContent__title {
  @include font-body-mono;
  margin-bottom: px(38);

  @include bp(tablet) {
    margin-bottom: px(48);
  }
}

.spec__materialTitles {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column;
  opacity: 0;
}

.spec__materialTitle {
  @include reset-button;
  @include font-h5;
  line-height: 1.1;
  opacity: 0.2;

  &.isActive {
    opacity: 1;
  }
}

.spec {
  position: relative;
  padding-top: px(23);
  padding-bottom: px(23);
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;

  @include bp(tablet) {
    padding-top: px(27);
    padding-bottom: px(27);
  }
}

.spec__line {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: var(--fg);
  transform: scaleX(0);
  transform-origin: left;
}

.spec__type {
  @include font-body-mono;
  position: absolute;
  left: 0;
  top: px(13);
}

.label {
  @include font-h5;
}
