@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.ProductHeroMaterialDropdown {
  color: var(--fg-invert);
  position: relative;
  z-index: 5;

  --material-button-height: #{px(50)};

  @include bp(tablet) {
    margin-top: px(70);
  }
}

.mainButton {
  @include reset-button;
  height: var(--material-button-height);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  background-color: var(--bg-invert);
  padding-right: px(16);
  gap: px(5);
}

.mainButton__materialTitle {
  @include font-body-mono-2;
  position: absolute;
  left: px(16);
  top: 50%;
  transform: translateY(-50%);
  color: var(--fg-invert);
  text-transform: uppercase;
}

.mainButton__material {
  @include font-body-7;
}

.mainButton__arrow {
  display: block;
  width: 0;
  height: 0;
  border-left: px(5) solid transparent;
  border-right: px(5) solid transparent;
  border-top: px(8) solid var(--fg-invert);
}

.buttonsList {
  width: 100%;
  pointer-events: none;
  position: absolute;
  top: calc(100% + 1px);
  left: 0;
  display: flex;
  flex-direction: column;
  gap: 1px;
}

.buttonList__item {
  width: 100%;
}

.buttonList__button {
  width: 100%;
  @include reset-button;
  height: var(--material-button-height);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 px(16);
  gap: px(30);
  position: relative;
}

.buttonList__button__bg {
  @include position-100(absolute);
  background-color: var(--bg-invert);
  transform: scaleX(0);
  transform-origin: left;
  transition: background-color 0.4s;

  @include hover {
    background-color: var(--material-dropdown-bg);
  }
}

.buttonList__button__index {
  @include font-body-mono-2;
  position: relative;
  z-index: 2;
  opacity: 0;
  transform: px(20);
}

.buttonList__button__title {
  @include font-body-7;
  position: relative;
  z-index: 2;
  opacity: 0;
  transform: px(20);
}
