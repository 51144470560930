@import "@/styles/shared";
.container {
  @include flex-center;
  height: calc(100svh - var(--footer-height));
  padding-left: var(--page-gutter);
  padding-right: var(--page-gutter);
}

.inner {
  width: 100%;
  position: relative;
}

.line {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: 1px;
  background-color: var(--fg);
}

.textContent {
  display: grid;
  grid-template-columns: 0.5fr 0.5fr;
  padding-top: px(16);

  @include bp(tablet) {
    padding-top: px(22);
    grid-template-columns: 0.5fr 0.33fr 0.17fr;
  }
}

.mobileText {
  @include font-body-6;
  position: absolute;
  bottom: calc(100% + #{px(10)});
  left: 0;
  opacity: 0.5;
}

.title {
  @include font-h1;
}

.error {
  @include font-h1;
  text-align: right;

  @include bp(tablet) {
    text-align: left;
  }
}

.text {
  @include font-body-5;
  justify-content: flex-end;
  display: none;

  @include bp(tablet) {
    display: flex;
  }
}
