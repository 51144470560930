@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.LithLogo {
  padding-left: var(--page-gutter);
  padding-right: var(--page-gutter);
}

.svg {
  width: 100%;
  display: block;
}
