@import "@/styles/shared";
.StandaloneMedia {
  padding-left: var(--page-gutter);
  padding-right: var(--page-gutter);
}

.topContent {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
}

.mediaContainer {
  position: relative;
  aspect-ratio: 1357/770;
  margin-top: px(9);

  @include bp(tablet) {
    margin-top: px(17);
  }
}

.image,
.video {
  @include position-100(absolute);
  object-fit: cover;
}

.topRightTitle {
  @include font-body-5;
}

.link {
  @include position-100(absolute);
  z-index: 3;
  opacity: 0;
}
