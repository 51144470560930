@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.ProductGridSlider {
  display: flex;
  align-items: center;
  gap: px(20);
  transition: opacity 0.2s;

  &.hidden {
    pointer-events: none;
    opacity: 0;
  }
}

.field {
  width: px(64);
  margin-left: auto;
  margin-right: auto;
}

.viewText {
  @include font-body-mono;
}

.inputslider {
  position: relative;

  input {
    display: none;
  }

  .area {
    position: relative;
    height: px(28);

    .track {
      position: absolute;
      top: 50%;
      left: 0;
      background-color: var(--fg);
      width: 100%;
      height: 1px;
      border-radius: 3px;
      z-index: 1;
      transform: translateY(-50%);
    }

    .fill {
      position: absolute;
      top: 50%;
      left: 0;
      background-color: var(--fg);
      width: 0;
      height: 1px;
      border-radius: 3px;
      z-index: 2;
      transform: translateY(-50%);
    }

    .knob {
      @include flex-center;
      position: absolute;
      top: 0;
      left: 0;
      width: px(28);
      height: px(28);
      z-index: 3;
    }

    .knobInner {
      display: block;
      width: px(6);
      height: px(9);
      background-color: var(--fg);
    }
  }
}
