@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.ProductGridMovingBoxes {
  &.textView {
    max-height: 0;
    position: relative;
    overflow: hidden;
  }
}

.box {
  outline: 1px solid var(--fg);
  transition: outline 0.2s;

  [data-active-view='COLUMN'] & {
    outline-width: 0.5px;
  }

  [data-active-view='SMALL'] & {
    outline-width: 1.5px;
  }
}
