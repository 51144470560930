@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.ProductHero {
}

.header {
  margin-top: px(14);
  padding-left: var(--page-gutter);
  padding-right: var(--page-gutter);
  display: grid;
  grid-template-columns: 0.5fr 0.5fr;
  row-gap: px(12);

  @include bp(tablet) {
    row-gap: 0;
    margin-top: px(10);
    grid-template-columns: 0.35fr 0.35fr 0.3fr;
  }
}

.title {
  @include font-h3;
  line-height: 1 !important;

  @include bp(tablet, true) {
    text-align: right;
  }
}

.model {
  @include font-h3;
  line-height: 1 !important;
}

.expandText {
  @include font-body-mono;
  opacity: 0.5;

  @include bp(tablet, true) {
    display: none;
  }
}

.count {
  @include font-body-mono;
  text-align: right;
  opacity: 0.5;

  @include bp(tablet, true) {
    text-align: left;
  }
}

.arrowsContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: px(8);
  transform: translateX(#{px(-3)});

  @include bp(tablet) {
    display: none;
  }
}

.arrowsContainer__arrowButton {
  @include reset-button;
  opacity: 0.5;
  pointer-events: none;

  &.isActive {
    opacity: 1;
    pointer-events: all;
  }

  svg {
    width: px(15);
    display: block;
  }

  &.left {
    svg {
      transform: rotate(-135deg);
    }
  }

  &.right {
    svg {
      transform: rotate(45deg);
    }
  }
}

.content {
  padding-left: var(--page-gutter);
  padding-right: var(--page-gutter);
  margin-top: px(20);

  @include bp(tablet) {
    margin-top: px(67);
  }
}

.contentHeader {
  border-bottom: 1px solid var(--fg);
  align-items: center;
  justify-content: space-between;
  padding-bottom: px(37);
  display: none;

  @include bp(tablet) {
    display: flex;
  }
}

.contentHeader__details {
  @include font-h5;
  opacity: 0.5;
}

.contentHeader__scroll {
  @include font-h5;
}

.contentGrid {
  @include bp(tablet) {
    border-bottom: 1px solid var(--fg);
    grid-template-columns: 0.65fr 0.35fr;
    display: grid;
    gap: var(--page-gutter);
  }
}

.contentGrid__details,
.contentGrid__detailViewAndDropdownContainer {
  padding-top: var(--page-gutter);

  @include bp(tablet) {
    padding-bottom: px(65);
  }
}

.contentGrid__details {
  border-top: 1px solid var(--fg);

  @include bp(tablet) {
    border-top: none;
    border-right: 1px solid var(--fg);
    padding-right: px(138);
  }
}

.contentGrid__detailViewAndDropdownContainer {
  margin-top: px(30);

  @include bp(tablet) {
    padding-left: px(109);
    text-align: right;
  }
}

.idNumberTitle {
  @include font-body-mono;
  margin-bottom: px(58);
  display: none;

  @include bp(tablet) {
    display: block;
  }
}

.idNumber {
  @include font-h5;
  margin-bottom: px(57);
  opacity: 0.5;
  display: none;

  @include bp(tablet) {
    display: block;
  }
}

.detailViewContainer {
  margin-bottom: px(132);

  @include bp(tablet, true) {
    display: none !important;
  }
}

.materialDropdown {
  margin-bottom: px(14);
}

.contactLink {
  @include font-body-mono-2;
  @include flex-center;
  height: px(50);
  margin-top: px(14);
  width: 100%;
  border: 1px solid var(--fg);
  text-transform: uppercase;
  position: relative;
  transition: opacity 0.2s;

  .materialDropdownOpen & {
    opacity: 0;
  }

  &::after {
    content: '';
    display: block;
    @include position-100(absolute);
    z-index: 1;
    transform-origin: left;
    transform: scaleX(0);
    background-color: var(--bg-invert);
    transition: transform 0.6s;
  }

  @include hover {
    &::after {
      transform: scaleX(1);
    }

    .contactLink__text {
      color: var(--fg-invert);
    }
  }
}

.contactLink__text {
  position: relative;
  z-index: 2;
  transition: color 0.6s;
}

.paletteContainer {
  display: flex;
}

.paletteContainer__item {
  @include font-body-6;
}

.paletteContainer__type {
  margin-bottom: px(10);
}

.paletteContainer__box {
  width: px(100);
  height: px(100);
}

.paletteContainer__boxLabel {
  background-color: white;
  color: black;
  font-size: 12px;
}
